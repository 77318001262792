export const environment = {
  production: true,
  isDemo: false,
  firebase: {
    apiKey: "AIzaSyB0aTL4tc16dJpPbPyGuv36GZmtfpxoi9w",
    authDomain: "enroll-here-dialer.firebaseapp.com",
    projectId: "enroll-here-dialer",
    storageBucket: "enroll-here-dialer.appspot.com",
    messagingSenderId: "754030235929",
    appId: "1:754030235929:web:01ce1218ae21e1280b3588",
    measurementId: "G-0D5E1K5700",
    appCheckRecaptchaSiteKey: '6LcyrnEqAAAAAAaEKnMr7kroa0CVmaQccVHCRw1c'
  },
  googleMapsApiKey: 'AIzaSyAeCSDZJ_E7kdeAlwRwH-tlmIax1uUyPno',

  enrollhereBaseUrl: 'https://app.enrollhere.com',
  loginUrl: 'https://app.enrollhere.com/login?redirect=dialer-publisher',
  elasticHttp: 'https://enrollhere.es.us-east1.gcp.elastic-cloud.com/',
  elasticPrefix: 'enrollhere-dialer_',
  elasticPrefixBase: 'enrollhere_',
  devVersion: '{BUILD_VERSION}',
  sentry: {
    dsn: "https://d9c25ff92f1e7e486ac0cce0490c1b0d@o4506558808064000.ingest.us.sentry.io/4508218328219648",
    tracePropagationTargets: [/^https:\/\/publisher\.dialer\.enrollhere\.com/],
    environment: 'production',
  },
  // intercom: {
  //   appId: 'bnvi8id7',
  // },
};
